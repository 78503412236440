<template>
  <Layout>Billing</Layout>
</template>

<script>
import Layout from "@/modules/xpbx/components/home/layout/XPBXLayout.vue";

export default {
  name: "Billing",

  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
</style>